export const status = {
  EXF: "EXF",
  CNT: "CNT",
  PUC: "PUC",
};

export const aidEnabledCatDocIds = [1, 2, 8, 60, 61, 62];
export const multipPageCatDocIds = [2, 60, 8, 62];

export const isBirthCertificate = (catDocId, docTypes) =>
  docTypes?.usBirthCertificate === catDocId;

export const isPassPort = (catDocId, docTypes) =>
  docTypes?.usPassport === catDocId;

export const isPassportCard = (catDocId, docTypes) =>
  docTypes?.usPassportCard === catDocId;

export const isDriversLicense = (catDocId, docTypes) =>
  docTypes?.drivingLicenseDocument === catDocId;

export const isAbbyyFlow = (ATD) => ["DNQ", "EXF", "XAD"].includes(ATD);

export const isMDLMobile = (applicationType) => applicationType === "mdl";

export const autoSubmitOptIn = {
  OPTED_IN: "optedIn",
  OPTED_OUT: "optedOut",
};
