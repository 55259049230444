import {
  callMatchSelfieLivenessOnly,
  callPresenceVerificationOptIn,
  useApplication,
  useDocumentUploads,
  getSelfieConfigurations,
  APPLICATION_TYPE,
} from "@ca-dmv-radv/data";
import useAIDCapture from "@ca-dmv-radv/files/src/useAIDCapture";
import { useTranslation } from "@ca-dmv-radv/translation";
import { useMounted } from "@ca-dmv-radv/utilities";
import InitializeBBY from "@ca-dmv-radv/utilities/src/abby-util";
import { Button } from "@ca-dmv/core";
import React, { useEffect, useState, useRef } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import IprooveCapture from "../IprooveCapture";
import SelfieTemplateHeader from "./SelfieTempleHeader";

import {
  BUTTON_TEXT,
  generateUrl,
  LOADING_MESSAGES,
  OPT_IN_OUT_MESSAGE,
  STATUS_TEXT,
  STATUSID,
  iproovPendingRetakePV,
  pendingRetakePV,
  getCaptureType,
  LOADING_TIMER
} from "./constants";
import LoadingIndicator from "./Loader";
import { invokeIProov } from "../helpers/invokeiProov";

export default function PresenceVerfication({
  onClose,
  setShouldPingDocumentUploads,
  program,
}) {
  const [currentStep, setCurrentStep] = useState(program !== APPLICATION_TYPE.DV ? 1 : 0);
  const { t } = useTranslation();
  const { documentUploads, fetchDocumentUploads } = useDocumentUploads();
  const { application, applicationType } = useApplication();
  const [status, setStatus] = useState(); // set the status ID once liveness api succeeded
  const [error, setError] = useState();
  const [isCompleted, setIsCompleted] = useState(false);
  const [selfieConfigurations, setSelfieConfigurations] = useState({});
  const [iproovToken, setIproovToken] = useState(null);
  const [loadingMessage, setLoadMessage] = useState();
  const [loaderRef, setLoaderRef] = useState();
  const mounted = useMounted();
  const [setErrorAbbyyLoad] = useState();
  const navigate = useNavigate();
  const selfieDocument = documentUploads[6]?.[0];
  const captureType = getCaptureType(selfieDocument);
  const [isLoading, setIsLoading] = useState(captureType === "IPROOV" ? true : false);

  const timeoutRef = useRef();

  // global variable so useABBYY behaves like a singleton
  let isLoadedOnce = false;

  // invoke iProov register/authenticate calls first
  useEffect(async () => {
    if (iproovPendingRetakePV(selfieDocument)) {
      try {
        setIsLoading(true);
        const authToken = await invokeIProov(application, applicationType);
        setIproovToken(authToken);
      } catch (error) {
        setError(
          t(
            "screen-modal-presence-verification-internal-error",
            "Something went wrong. Please try again."
          )
        );
        setCurrentStep(3);
      } finally {
        fetchDocumentUploads();
        setIsLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    const statusId = selfieDocument?.statusId;

    if (statusId === STATUSID.RETAKE) {
      setIsLoading(false);
      if (statusId) setStatus(statusId);
      setError(
        t(
          "screen-modal-presence-verification-retake-error",
          "Something went wrong. Please try again."
        )
      );
      setCurrentStep(3);
    }

    if (documentUploads.length != 0 && program !== APPLICATION_TYPE.DV) {
      const url = generateUrl(application, documentUploads);

      if (url && url !== "presence-verification") {
        navigate(`../${url}`);
      }
    } else if (isCompleted && Object.values(STATUSID).includes(statusId)) {
      setCurrentStep(3);
      setIsLoading(false);
      setStatus(statusId);
      setIsCompleted();
      setShouldPingDocumentUploads(false);

      if (loaderRef) {
        clearTimeout(loaderRef);
      }
    }
  }, [documentUploads]);

  useEffect(() => {
    if (isCompleted) {
      const index = LOADING_MESSAGES.indexOf(loadingMessage);

      if (index < LOADING_MESSAGES.length - 1) {
        const loaderReference = setTimeout(
          () => setLoadMessage(LOADING_MESSAGES[index + 1]),
          5000
        );
        setLoaderRef(loaderReference);
      }
    }
  }, [loadingMessage]);

  useEffect(() => {
    if (isLoadedOnce || !isMobile || !mounted) {
      return;
    }

    // prevent script/styles from loading multiple times.
    isLoadedOnce = true;

    InitializeBBY(setErrorAbbyyLoad);
  }, [isMobile, mounted]);

  function resetAID() {
    setError();
    setStatus();
    setIsCompleted();
    setIsLoading(false);

    if (loaderRef) {
      clearTimeout(loaderRef);
    }
  }

  useEffect(() => {
    // back to step 1 on new session if retake required
    const statusId = selfieDocument?.statusId;

    if (statusId === STATUSID.RETAKE)
      setCurrentStep(1);

    if (pendingRetakePV(selfieDocument))
      resetAID();
  }, []);

  useEffect(() => {
    const getConfigurations = async () => {
      const response = await getSelfieConfigurations(applicationType);
      setSelfieConfigurations(response.data);
    }
    getConfigurations();
  }, []);

  // Post selfies for matchSelfie liveness only
  async function postLiveness(selfieImage, captureMode) {
    setError();
    setIsLoading(true);
    timeoutRef.current = setTimeout(() => {
      setCurrentStep(3);
      setIsLoading(false);
      setStatus(STATUSID.RETAKE);
      setError(t("screen-modal-presence-verification-internal-error", "Something went wrong. Please try again."));
    }, LOADING_TIMER);
    callMatchSelfieLivenessOnly(selfieImage, applicationType, captureMode)
      .then((response) => {
        console.log("Match Selfie called and response received.");
        console.log(JSON.stringify(response));
        fetchDocumentUploads();
        clearTimeout(timeoutRef.current);
        if (!response?.success) {
          setCurrentStep(3);
          setIsLoading(false);
          setStatus(STATUSID.FAILED);
          setError(response?.data?.message);
        } else {
          setShouldPingDocumentUploads(true);
          setIsCompleted(true);
          setLoadMessage(LOADING_MESSAGES[0]);
        }
      })
      .catch((error) => {
        console.log("Error with Match Selfie");
        console.log(JSON.stringify(error));
        setStatus(STATUSID.FAILED);
        setError(
          t(
            "screen-modal-presence-verification-internal-error",
            "Something went wrong. Please try again."
          )
        );
      });
  }

  async function invokeAID(event) {
    try {
      setIsLoading(true);
      setStatus();
      setError();

      const selfieSettings = new SelfieSettings();
      selfieSettings.captureMode = selfieConfigurations.isManual ? "Manual" : "Auto";
      selfieSettings.overlayText = t(
        "screen-modal-presence-verification-selfie-overlay-text",
        "Please align your face within the frame. Once the frame turns green, select the button below to capture the image."
      );
      selfieSettings.overlayTextAuto = t(
        "screen-modal-presence-verification-selfie-overlay-text-auto",
        "Please align your face within the frame. Once the frame turns green, hold and the image will auto-capture."
      );

      const aidImages = await useAIDCapture(event, selfieSettings);
      setIsLoading(false);
      if (event === "SELFIE") {
        postLiveness(aidImages[0].dataUrl, selfieSettings.captureMode);
      }
    } catch (e) {
      setIsLoading(false);
      console.log("Failed to capture selfie.", e);
    }
  }

  const handleStatusClick = () => {
    if (status === STATUSID.RETAKE) {
      if (captureType === "AID") {
        setCurrentStep(1);
      } else if (captureType === "IPROOV") {
        window.location.reload();
      }
    } else {
      onClose(status === STATUSID.SUCCESS || status === STATUSID.FAILED);
    }

    if (pendingRetakePV(selfieDocument)) resetAID();
  };

  const onhandleOptInOut = async (hasOpted) => {
    setIsLoading(true);
    try {
      await callPresenceVerificationOptIn(hasOpted);
      setIsLoading(false);
      if (!hasOpted) {
        onClose(true);
      }
      if (hasOpted) {
        setCurrentStep(1);
      }
    } catch (err) {
      console.log("error------->", err);
    }
  };

  const getTemplateByStep = () => {
    switch (currentStep) {
      case 1:
        if (pendingRetakePV(selfieDocument)) {
          return (<SelfieTemplate
            onClose={() => onClose()}
            onSubmit={async () => await invokeAID("SELFIE")}
            applicationType={applicationType}
          />);
        } else if (iproovPendingRetakePV(selfieDocument)) {
          return (<IprooveCapture
            application={application}
            applicationType={applicationType}
            setCurrentStep={setCurrentStep}
            setStatus={setStatus}
            setError={setError}
            iproovToken={iproovToken}
            fetchDocumentUploads={fetchDocumentUploads}
          />);
        }
      case 3: {
        const message =
          error ||
          (STATUS_TEXT[status]
            ? t(STATUS_TEXT[status].key, STATUS_TEXT[status].value)
            : "");
        const buttonText = BUTTON_TEXT[status]
          ? t(BUTTON_TEXT[status].key, BUTTON_TEXT[status].value)
          : t("screen-modal-presence-verification-next", "Next");
        return (
          <DisplayStatusTemplate
            message={message}
            buttonText={buttonText}
            onClickHandler={handleStatusClick}
          />
        );
      }
      default:
        return <InstructionTemplate onhandleOptInOut={onhandleOptInOut} />;
    }
  };
  return (
    <>
      {isLoading && (
        <LoadingIndicator
          message={
            isCompleted && loadingMessage
              ? t(loadingMessage.key, loadingMessage.value)
              : ""
          }
        />
      )}

      {!isLoading && getTemplateByStep()}
    </>
  );
}

function InstructionTemplate({ onhandleOptInOut }) {
  const { t } = useTranslation();
  return (
    <>
      <p className="text--black mb-30 mt-50 max-width--600">
        {t(OPT_IN_OUT_MESSAGE.message1.key, OPT_IN_OUT_MESSAGE.message1.value)}
      </p>
      <p className="text--black mb-30 mt-30 max-width--600">
        {t(OPT_IN_OUT_MESSAGE.message2.key, OPT_IN_OUT_MESSAGE.message2.value)}
      </p>
      <p className="text--black mb-60 mt-30 max-width--600">
        {t(OPT_IN_OUT_MESSAGE.message3.key, OPT_IN_OUT_MESSAGE.message3.value)}
      </p>
      <div className="flex flex--justify-between mb-12 mt-24">
        <Button
          buttonClass="btn--secondary"
          onClick={() => onhandleOptInOut && onhandleOptInOut(false)}
        >
          {t("screen-modal-presence-verification-optout", "No")}
        </Button>
        <Button
          buttonClass="btn--secondary"
          onClick={() => onhandleOptInOut && onhandleOptInOut(true)}
        >
          {t("screen-modal-presence-verification-optin", "Yes")}
        </Button>
      </div>
    </>
  );
}

function SelfieTemplate({
  onClose,
  onSubmit,
  showCancel,
  applicationType,
}) {
  const { t } = useTranslation();
  const btnAlignment = !showCancel ? "justify-end" : "justify-between";
  return (
    <div>
      <SelfieTemplateHeader applicationType={applicationType} />
      <div className={`flex flex--${btnAlignment} mb-12 mt-24`}>
        {showCancel && (
          <Button buttonClass="btn--secondary" onClick={onClose}>
            {t("screen-modal-presence-verification-cancel", "Cancel")}
          </Button>
        )}
        <Button buttonClass="btn--secondary" onClick={onSubmit}>
          {t(
            "screen-modal-presence-verification-selfie1",
            "Enable Camera"
          )}
        </Button>
      </div>
    </div>
  );
}

function DisplayStatusTemplate({ message, buttonText, onClickHandler }) {
  return (
    <div>
      <h5 className="pb-24">{message}</h5>
      <div className="flex flex--justify-end mt-90 pt-90">
        <Button buttonClass="btn--secondary" onClick={onClickHandler}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
}
