import { APPLICATION_TYPE, DOC_UPLOAD_STATUS_IDS, useApplication, useFileUpload, useGoogleAnalytics, useModals } from '@ca-dmv-radv/data';
import {
  CAPTURING_PHOTOS_IDLE,
  PROCESSING_PHOTOS_ERROR,
  PROCESSING_PHOTOS_IDLE,
  PROCESSING_PHOTOS_PROCESSING,
  PROCESSING_PHOTOS_SUCCESS,
  PROCESSING_PHOTOS_UPLOADING,
  useABBYYCapture,
} from '@ca-dmv-radv/files';
import { Trans, useTranslation } from '@ca-dmv-radv/translation';
import { ABBYY_ERROR_STOP, DocumentUpload } from '@ca-dmv/core';
import { React, useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

import { authenticateDocument } from '../../../../../packages/data/src/api';
import { useDocumentUploads } from '../../../../../packages/data/src/document-uploads-context-provider';
import AIDDocumentCapture from '../AIDDocumentCapture';
import { isAbbyyFlow, isBirthCertificate, isDriversLicense, isMDLMobile, isPassPort, isPassportCard, status } from '../helpers/constants';
import useTranslateDocumentName from '../helpers/useTranslateDocumentName';
import RequiredPhotos from '../RequiredPhotos';
import Upload from '../Upload';
import ErrorExplanation from './ErrorExplanation';
import errorStatus from './errorStatus';
import MobileVerificationModal from './MobileVerificationModal';
import ProcessingPhotosErrorModal from './ProcessingPhotosErrorModal';
import ProcessingPhotosModal from './ProcessingPhotosModal';
import ProcessingPhotosSuccessModal from './ProcessingPhotosSuccessModal';
import SelectDifferentDocument from './SelectDifferentDocument';
import TakePhotoButton from './TakePhotoButton';
import { isFailureStatus, isPendingStatus, isRetryStatus, isSuccessStatus, isPendingConfirmationStatus, isAIDCapture } from '../PresenceVerification/constants';
import { useNavigate } from 'react-router-dom';

let transactionId;
const aidEnabledDocs = [1, 2, 60, 61, 62];

export default function Document({ containerClass, documentUpload, onCancelDocument, onSelectDifferentDocument, selectDifferentDocumentLabel, confirmFields, setConfirmFields, showIdentityForm, setShowIdentityForm }) {
  if (!documentUpload) {
    return null;
  }
  const { t } = useTranslation();
  const { uploadingDocumentId, setUploadingDocumentId, takingPhotoDocumentId, setTakingPhotoDocumentId, selectDifferentDocumentId, setSelectDifferentDocumentId } = useModals(null);
  const { documentUploads, fetchDocumentUploads, setShouldPingDocumentUploads, isMDLDocument } = useDocumentUploads();
  const { setFiles, postUploads } = useFileUpload();
  const { fetchApplication, applicationType, docTypes } = useApplication();
  const { setAnalyticsAction } = useGoogleAnalytics();
  const translatedDocumentName = useTranslateDocumentName(documentUpload, applicationType);

  const uploadDocumentFiles = useCallback(
    async (doc) => {
      setShouldPingDocumentUploads(false);
      setAnalyticsAction(`Uploading ${doc.category}${doc.doc ? ` ${doc.doc}` : ""}`);
      const uploadSuccess = await postUploads(doc.id, doc.catDocId);
      await Promise.all([fetchApplication(), fetchDocumentUploads()]);
      setShouldPingDocumentUploads(true);
      setFiles((currentFiles) => {
        const newFiles = { ...currentFiles };
        delete newFiles[doc.id];
        return newFiles;
      });
      return uploadSuccess;
    },
    [setShouldPingDocumentUploads, setAnalyticsAction, postUploads, fetchApplication, fetchDocumentUploads, setFiles]
  );

  const { requiredPhotos, setRequiredPhotos, processingPhotos, setProcessingPhotos, verificationRequired, setVerificationRequired, resetUseABBYState, setIsCapturing, mutliPageError, setMultipageError } = useABBYYCapture({ chosenDocument: documentUpload, uploadDocumentFiles });
  const { setPassportFormData, passportFormData, resetFormData } = useFileUpload();
  const documentErrorStatus = errorStatus({ documentUpload });
  const showSelectDifferentDocument = (![DOC_UPLOAD_STATUS_IDS.VERIFIED, DOC_UPLOAD_STATUS_IDS.VERIFYING, DOC_UPLOAD_STATUS_IDS.UNDER_REVIEW, DOC_UPLOAD_STATUS_IDS.FAILED, DOC_UPLOAD_STATUS_IDS.UPLOADING, DOC_UPLOAD_STATUS_IDS.PROCESSING].includes(documentUpload.statusId) || documentErrorStatus === ABBYY_ERROR_STOP || (documentUpload.ATD === "PUC" && !confirmFields)) && documentUpload.categoryId !== 7;

  const [takePhotoClicked, setTakePhotoClicked] = useState(false);
  const [extractedDetails, setExtractedDetails] = useState({});
  const [extractInProgress, setExtractInProgress] = useState(false);
  const abbyyFlow = isAbbyyFlow(documentUpload?.ATD);
  const navigate = useNavigate();

  const showUploadPhoto = () => {
    if (isMDLMobile(applicationType) && !isMobile) {
      return false;
    } else {
      return !isMobile && (documentUpload.statusId === DOC_UPLOAD_STATUS_IDS.PENDING || documentErrorStatus === ABBYY_ERROR_STOP);
    }
  };
  const showTakePhoto = () => {
    if (isMDLMobile(applicationType) && !isMobile) {
      return false;
    } else {
      return documentUpload.statusId === DOC_UPLOAD_STATUS_IDS.PENDING || ([1, 7, 8].includes(documentUpload.categoryId) && 
        (isRetryStatus(documentUpload?.ATD) || (isPendingConfirmationStatus(documentUpload?.ATD) && !confirmFields))) || 
        (documentUpload.categoryId === 6 && (isRetryStatus(documentUpload?.MTF) || isRetryStatus(documentUpload?.MTS))) || documentErrorStatus === ABBYY_ERROR_STOP;
    }
  };
  //Post authenticateDocument
  const postAuthenticateDocument = async (documentUpload, applicationType) => {
    setExtractInProgress(true);
    await authenticateDocument(documentUpload.id, applicationType).then((response) => {
      console.log("Authenticate Document API is called and response received.");
      console.log(JSON.stringify(response));
      transactionId = response.data.transactionId;
      setExtractedDetails(response.data.documentDetails);
      if (isPendingConfirmationStatus(response.data.decisionCode)) {
        if (isPassPort(documentUpload.catDocId, docTypes) || isPassportCard(documentUpload.catDocId, docTypes)) {
          setPassportFormData({
            ...passportFormData,
            Passport_Number: response.data.documentDetails?.documentNumber,
            Passport_Expiration_Date: response.data.documentDetails?.documentExpirationDate,
          });
        }    
        setShowIdentityForm(true);
      } else if (isSuccessStatus(response.data.decisionCode) && applicationType === APPLICATION_TYPE.VDL) {
        navigate("/final-confirmation");
      }
    });
    await fetchDocumentUploads();
    setProcessingPhotos(PROCESSING_PHOTOS_IDLE);
    setTakingPhotoDocumentId(null);
    setExtractInProgress(false);
  };

  const showChangeImage = () => {
    if (isMDLMobile(applicationType) && !isMobile) {
      return false;
    } else {
      return ![DOC_UPLOAD_STATUS_IDS.VERIFIED, DOC_UPLOAD_STATUS_IDS.VERIFYING, DOC_UPLOAD_STATUS_IDS.PROCESSING, DOC_UPLOAD_STATUS_IDS.UPLOADING, DOC_UPLOAD_STATUS_IDS.UNDER_REVIEW, DOC_UPLOAD_STATUS_IDS.FAILED].includes(documentUpload.statusId);
    }
  };
  const showActiveCheck = [DOC_UPLOAD_STATUS_IDS.VERIFYING, DOC_UPLOAD_STATUS_IDS.UNDER_REVIEW, DOC_UPLOAD_STATUS_IDS.FAILED, DOC_UPLOAD_STATUS_IDS.PROCESSING, DOC_UPLOAD_STATUS_IDS.UPLOADING].includes(documentUpload.statusId);

  const showSuccessCheck = DOC_UPLOAD_STATUS_IDS.VERIFIED === documentUpload.statusId;
  const isSelectedDocumentID = documentUpload.id === takingPhotoDocumentId;

  const processingPhotosError = mutliPageError ? t("screens-Dashboard-mutliPageError", "Please provide required Front and Back photos of document.") : t("screens-Dashboard-processingPhotoError", "We encountered an error while processing your photos");

  const countErrorCodes = () => {
    const errorCodes = documentUpload.validationData?.errorCodes || [];
    // remove duplicate codes
    return errorCodes.filter((code, index) => errorCodes.indexOf(code) === index).length;
  };

  useEffect(() => {
    if (processingPhotos === PROCESSING_PHOTOS_SUCCESS && [1, 7, 8].includes(documentUpload.categoryId)) {
      if (!isAbbyyFlow(documentUpload.ATD) && !isSuccessStatus(documentUpload.ATD)) {
        let documentType = isPassPort(documentUpload.catDocId, docTypes) ? "Passport" : "License";
        postAuthenticateDocument(documentUpload, applicationType);
        fetchDocumentUploads();
      }
    }
    if (processingPhotos === PROCESSING_PHOTOS_PROCESSING && abbyyFlow) {
      uploadPhotos();
    }
  }, [processingPhotos]);

  useEffect(() => {
    if ([1, 7, 8].includes(documentUpload.categoryId)) {
      if (isPendingStatus(documentUpload.ATD) && Object.keys(extractedDetails).length !== 0) {
        // setShowConfirmFields(true);
      } else if (isRetryStatus(documentUpload.ATD)) {
        setShowIdentityForm(false);
      } else if (isFailureStatus(documentUpload.ATD)) {
        //setAidFailure(true);
      }
    }
    fetchDocumentUploads();
  }, [documentUpload.ATD]);

  const onTakePhotoClick = () => {
    setTakePhotoClicked(true);
    setTakingPhotoDocumentId(documentUpload.id);
    resetUseABBYState();
    resetFormData();
    if ([1, 7, 8].includes(documentUpload.categoryId)) {
      if (isBirthCertificate(documentUpload.catDocId, docTypes) || isPassPort(documentUpload.catDocId, docTypes) || isPassportCard(documentUpload.catDocId, docTypes)) { 
        setVerificationRequired("required");
      } 
      if (isAIDCapture(documentUpload.ATD) && aidEnabledDocs.includes(documentUpload.catDocId)) {
        setRequiredPhotos("required");
      } else if (abbyyFlow && aidEnabledDocs.includes(documentUpload.catDocId)) {
        isPassPort(documentUpload.catDocId, docTypes) && setShowIdentityForm(true);
        isPassportCard(documentUpload.catDocId, docTypes) && setRequiredPhotos("required");
      }
    }
  };

  const processingErrorModalClose = () => {
    if (mutliPageError) {
      if (isPassportCard(documentUpload?.catDocId, docTypes)) {
        resetFormData();
      }
      setRequiredPhotos("required");
      setMultipageError(false);
    }
    setProcessingPhotos(PROCESSING_PHOTOS_IDLE);
    setTakingPhotoDocumentId(null);
  };

  const uploadPhotos = async () => {
    const { id, catDocId, ATD } = documentUpload;
    const uploadSuccess = await postUploads(id, catDocId, ATD);
    if (uploadSuccess) {
      setProcessingPhotos(PROCESSING_PHOTOS_SUCCESS);
    } else {
      setProcessingPhotos(PROCESSING_PHOTOS_ERROR);
    }
  };

  const submitAIDCapture = () => {
    // setCloseModal(true)
    setProcessingPhotos(PROCESSING_PHOTOS_PROCESSING);
    setVerificationRequired("submitPhotos");
    uploadPhotos();
  };

  const determineShouldShowMobileVerificationModal = () => {
    const requiredPhotosCompleted = [null, "accepted"].includes(requiredPhotos);
    if (isMobile && (((verificationRequired === "required" && requiredPhotosCompleted && takePhotoClicked && isSelectedDocumentID)) || showIdentityForm)) {
      return true;
    }
    return false;
  }

  const shouldShowMobileVerificationModal = determineShouldShowMobileVerificationModal();

  const determineShouldShowRequiredPhotos = () => {
    if (isMobile && requiredPhotos === "required" && takePhotoClicked && isSelectedDocumentID) {
      if (isAbbyyFlow(documentUpload.ATD)) {
        return true;
      }
    }
    return false;
  }

  const shouldShowRequiredPhotos = determineShouldShowRequiredPhotos();

  return (
    <>
      <DocumentUpload
        legend={translatedDocumentName || documentUpload.name}
        containerClass={containerClass}
        showSelectDifferentDocument={showSelectDifferentDocument && !documentUpload.parentId}
        showUploadPhoto={showUploadPhoto()}
        showTakePhoto={showTakePhoto()}
        uploadImageLabel={t("screens-Dashboard-uploadPhoto", "Upload Photo")}
        takePhotoLabel={t("screens-Dashboard-takePhotoLabel", "Take Photo")}
        selectDifferentDocumentLabel={selectDifferentDocumentLabel || t("screens-Dashboard-changeLabel", "Select a Different Document")}
        orLabel={t("screens-Dashboard-orLabel", "or")}
        onUploadClick={() => {
          resetFormData();
          setUploadingDocumentId(documentUpload.id);
        }}
        onTakePhotoClick={onTakePhotoClick}
        onSelectDifferentDocument={() => {
          if (onSelectDifferentDocument) {
            onSelectDifferentDocument();
          } else {
            setSelectDifferentDocumentId(documentUpload.id);
          }
        }}
        errorStatus={documentErrorStatus}
        errorTitle={
          documentErrorStatus === ABBYY_ERROR_STOP ? (
            <Trans
              i18nKey="screens-Dashboard-abbyyErrorTitle"
              defaults="There was an error in your submission. Please correct the following {{items}} or select a different document."
              values={{
                items: countErrorCodes() > 1 ? "items" : "item",
              }}
            />
          ) : (
            ""
          )
        }
        errorExplanation={<ErrorExplanation documentUpload={documentUpload} />}
        showChangeImage={showChangeImage()}
        onChangeImage={() => {
          setUploadingDocumentId(documentUpload.id);
        }}
        showActiveCheck={showActiveCheck}
        showSuccessCheck={showSuccessCheck}
      />
      {documentUpload.id === uploadingDocumentId && (
        <Upload
          onClose={() => {
            setUploadingDocumentId(null);
          }}
          documentUpload={documentUpload}
          modalTitle={`${t("shared.uploadLabel", "Upload")} ${translatedDocumentName || documentUpload.name}`}
          uploadDocumentFiles={uploadDocumentFiles}
          docTypes={docTypes}
        />
      )}

      {isSelectedDocumentID && !isMobile && <TakePhotoButton documentUpload={documentUpload} />}

      {isSelectedDocumentID && requiredPhotos === "required" && processingPhotos === PROCESSING_PHOTOS_IDLE && isMobile && isAIDCapture(documentUpload.ATD) && (
        <AIDDocumentCapture
          documentName={documentUpload.name}
          onClose={() => {
            setRequiredPhotos("canceled");
            setTakingPhotoDocumentId(null);
          }}
          onCapture={() => {
            setVerificationRequired("accepted");
            setIsCapturing(CAPTURING_PHOTOS_IDLE);
          }}
          onConfirm={submitAIDCapture}
          requiredPages={documentUpload.requiredPages}
          document={documentUpload}
        ></AIDDocumentCapture>
      )}
      {shouldShowRequiredPhotos && (
        <RequiredPhotos
          documentName={documentUpload.name}
          onClose={() => {
            setRequiredPhotos("canceled");
            setTakingPhotoDocumentId(null);
            setTakePhotoClicked(false);
          }}
          onCapture={() => {
            if (isPassportCard(documentUpload.catDocId, docTypes)) {
              setShowIdentityForm(true);
            } else {
              setVerificationRequired("accepted");
            }
            setRequiredPhotos("accepted");
          }}
          onConfirm={() => {
            setVerificationRequired("submitPhotos");
          }}
          requiredPages={documentUpload.requiredPages}
          document={documentUpload}
        ></RequiredPhotos>
      )}
      { shouldShowMobileVerificationModal && (
        <MobileVerificationModal
          t={t}
          documentUpload={documentUpload}
          setVerificationRequired={setVerificationRequired}
          setShowIdentityForm={setShowIdentityForm}
          extractedDetails={extractedDetails}
          setConfirmFields={setConfirmFields}
          extractInProgress={extractInProgress}
          setExtractInProgress={setExtractInProgress}
          setRequiredPhotos={setRequiredPhotos}
          docTypes={docTypes}
          applicationType={applicationType}
          onClose={() => {
            if (documentUpload?.requiredPages) {
              setRequiredPhotos("required");
            }
            setTakingPhotoDocumentId(null);
            setShowIdentityForm(false);
            setTakePhotoClicked(false);
            // setIsCapturing(CAPTURING_PHOTOS_IDLE);
          }}
        />
      )}

      {((isSelectedDocumentID && [PROCESSING_PHOTOS_UPLOADING, PROCESSING_PHOTOS_PROCESSING].includes(processingPhotos)) || extractInProgress) && isMobile && <ProcessingPhotosModal t={t} />}

      {isSelectedDocumentID && processingPhotos === PROCESSING_PHOTOS_SUCCESS && isMobile && abbyyFlow && (
        <ProcessingPhotosSuccessModal
          onClose={() => {
            setProcessingPhotos(PROCESSING_PHOTOS_IDLE);
            setTakingPhotoDocumentId(null);
            fetchDocumentUploads();
          }}
          t={t}
        />
      )}

      {((isSelectedDocumentID && processingPhotos === PROCESSING_PHOTOS_ERROR) || mutliPageError) && isMobile && <ProcessingPhotosErrorModal onClose={processingErrorModalClose} error={processingPhotosError} t={t} />}

      {selectDifferentDocumentId === documentUpload.id && onCancelDocument && (
        <SelectDifferentDocument
          onConfirm={onCancelDocument}
          onClose={() => {
            setSelectDifferentDocumentId(null);
            setTakingPhotoDocumentId(null);
          }}
        />
      )}
    </>
  );
}

export { transactionId };
