import {
  useApplication,
  useDocumentUploads,
  useGoogleAnalytics,
  useNameChanges,
  useIdentityDocument,
} from "@ca-dmv-radv/data";
import { useCallback, useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function useConfirmNameNavigation({
  setShowErrors,
  errors,
  isEdited,
}) {
  const [navigating, setNavigating] = useState(false);
  const [requestNavigationCalled, setRequestNavigationCalled] = useState(false);
  const [navigationError, setNavigationError] = useState(false);

  const navigate = useNavigate();
  const { showIdentityNameOptions } = useIdentityDocument();
  const { fetchApplication } = useApplication();
  const {
    postConfirmName,
    nameMatches,
    selectedNameMatchesOption,
    setNameMatches,
    noDocsOption,
    postNameChanges,
    fetchNameChanges,
  } = useNameChanges();
  const { fetchDocumentUploads } = useDocumentUploads();
  const { setAnalyticsAction } = useGoogleAnalytics();

  const hasErrors = Boolean(Object.keys(errors).length);

  const shouldSetNameMatches = () => {
    const falseNameMatchesSuccess = !navigationError && selectedNameMatchesOption === false;
    const trueNameMatchesSuccess = selectedNameMatchesOption === true;
    return requestNavigationCalled && (falseNameMatchesSuccess || trueNameMatchesSuccess)
  }

  useEffect(() => {
    if (shouldSetNameMatches()) {
      setNameMatches(selectedNameMatchesOption);
    }
  }, [requestNavigationCalled]);

  const requestNavigation = useCallback(async () => {
    setRequestNavigationCalled(true);

    if (navigating) {
      return;
    }

    if (!showIdentityNameOptions) {
      navigate("/");
      return;
    }

    setShowErrors(hasErrors);

    if (hasErrors) {
      setNavigationError(true);
      return;
    }

    setNavigating(true);

    if (isEdited) {
      let success;
      success = await postConfirmName();

      if (!success) {
        setNavigating(false);
        setNavigationError(true);
        return;
      }

      if (noDocsOption !== null) {
        success = await postNameChanges();
        if (!success) {
          setNavigating(false);
          setNavigationError(true)
          return;
        }
        await Promise.all([fetchApplication(), fetchNameChanges()]);
      } else if (selectedNameMatchesOption && noDocsOption === null) {
        await Promise.all([
          fetchApplication(),
          fetchDocumentUploads(),
          fetchNameChanges(),
        ]);
      } else {
        await fetchNameChanges();
      }
    }

    setNavigationError(false);
    setAnalyticsAction("Submit");

    navigate(
      selectedNameMatchesOption || noDocsOption !== null ? "/" : "/identity/name-changes"
    );
  }, [
    navigating,
    hasErrors,
    isEdited,
    nameMatches,
    selectedNameMatchesOption,
    navigate,
    fetchApplication,
    fetchDocumentUploads,
    setShowErrors,
    postConfirmName,
    postNameChanges,
    noDocsOption,
  ]);

  return useMemo(
    () => ({
      requestNavigation,
      navigating,
    }),
    [requestNavigation, navigating]
  );
}
