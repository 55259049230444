import { useFileUpload, useModals } from '@ca-dmv-radv/data';
import { useMounted } from '@ca-dmv-radv/utilities';
import { useEffect, useMemo, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';

import useABBYY, { CAPTURING_PHOTOS_IDLE } from './useABBYY';
import { isAbbyyFlow } from '@ca-dmv-radv/screens/src/dashboard/helpers/constants';
import { isQualifiedStatus } from '@ca-dmv-radv/screens/src/dashboard/PresenceVerification/constants';

export const PROCESSING_PHOTOS_IDLE = "idle";
export const PROCESSING_PHOTOS_PROCESSING = "processing";
export const PROCESSING_PHOTOS_SUCCESS = "success";
export const PROCESSING_PHOTOS_ERROR = "error";
export const PROCESSING_PHOTOS_UPLOADING = "uploading";

export function useABBYYCapture({ chosenDocument, uploadDocumentFiles }) {
  const [processingPhotos, setProcessingPhotos] = useState(PROCESSING_PHOTOS_IDLE);
  const [requiredPhotos, setRequiredPhotos] = useState(null); // required, accepted, cancelled
  const [verificationRequired, setVerificationRequired] = useState("required"); // required, not-required, accepted, cancelled
  const [mutliPageError, setMultipageError] = useState(false);

  const mounted = useMounted();
  const { takingPhotoDocumentId } = useModals(null);
  const { handleWebCapture, hasLoadedABBYY, isCapturing, setCapturedImages, setIsCapturing, setAbbyLoadingError } = useABBYY();
  const { setFiles, setShowInputError } = useFileUpload();
  const requiredPagesLength = chosenDocument?.requiredPages?.length;

  const resetUseABBYState = () => {
    chosenDocument.isFront = false;
    chosenDocument.isBack = false;
    chosenDocument.frontImage = null;
    chosenDocument.backImage = null;
    setCapturedImages([]);
    setFiles(() => {
      const nextFiles = {};
      nextFiles[chosenDocument.id] = [];
      return nextFiles;
    });
    updateFilesState([]);
    setIsCapturing(CAPTURING_PHOTOS_IDLE);
    setAbbyLoadingError(null);
    setShowInputError(false);
  };

  const updateFilesState = (processedFiles) => {
    setFiles((currentFiles) => {
      // if we don't have files to process, reset the modal
      if (!processedFiles && !Object.values(currentFiles).length) {
        setProcessingPhotos(PROCESSING_PHOTOS_IDLE);
        return false;
      }

      const nextFiles = {};
      nextFiles[chosenDocument.id] = processedFiles;

      return nextFiles;
    });
  };

  useEffect(() => {
    if (["accepted", "submitPhotos"].includes(verificationRequired)) {
      return;
    }
    if ([1, 2, 3, 61, 62, 63].includes(chosenDocument?.catDocId)) {
      setVerificationRequired("required");
    } else {
      setVerificationRequired("not-required");
    }
  }, [chosenDocument, verificationRequired]);

  useEffect(() => {
    if (!hasLoadedABBYY || !chosenDocument || !takingPhotoDocumentId || !isMobile || processingPhotos !== PROCESSING_PHOTOS_IDLE || !mounted || isCapturing !== CAPTURING_PHOTOS_IDLE) {
      return;
    }

    (async () => {
      // This may become a generic instructional modal for
      // most docs of a category
      // https://tenup.teamwork.com/#tasks/19039081?c=9145966
      if (requiredPagesLength && verificationRequired !== "accepted" && requiredPhotos !== "accepted") {
        setRequiredPhotos("required");
        return;
        // }
      }

      if (chosenDocument.id === takingPhotoDocumentId && ["accepted", "submitPhotos", "not-required"].includes(verificationRequired)) {
        // handle web capture
        let fileList = [];
        //setVerificationRequired("oneMorePending");
        if (chosenDocument.requiredPages !== null) {
          if (chosenDocument.retakeFront) {
            fileList[0] = null;
            chosenDocument.retakeFront = false;
          }
          if (chosenDocument.retakeBack) {
            fileList[1] = null;
            chosenDocument.retakeBack = false;
          }

          if (fileList?.length !== requiredPagesLength || fileList[0] === null || fileList[1] === null) {
            try {
              fileList = await handleWebCapture(chosenDocument);
              if (isAbbyyFlow(chosenDocument?.ATD)) {
                if (fileList?.length === requiredPagesLength) {
                  updateFilesState(fileList);
                  setProcessingPhotos(PROCESSING_PHOTOS_PROCESSING);
                } else if (fileList?.length < requiredPagesLength) {
                  setMultipageError(true);
                }
              } else if (isQualifiedStatus(chosenDocument?.ATD)) {
                if (fileList?.length === requiredPagesLength) {
                  updateFilesState(fileList);
                }
              } else {
                setRequiredPhotos("cancelled");
              }
            } catch (error) {
              chosenDocument.accessCamera = false;
              setIsCapturing(CAPTURING_PHOTOS_IDLE);
              return;
            }
          }
        } else {
          try {
            fileList = await handleWebCapture(chosenDocument);
            if (fileList?.length) {
              updateFilesState(fileList);
              if (isAbbyyFlow(chosenDocument?.ATD)) {
                setProcessingPhotos(PROCESSING_PHOTOS_PROCESSING);
              }
            }
            return;
          } catch (error) {
            chosenDocument.accessCamera = false;
            setIsCapturing(CAPTURING_PHOTOS_IDLE);
            return;
          }
        }
      }
    })();
  }, [hasLoadedABBYY, chosenDocument, takingPhotoDocumentId, requiredPhotos, verificationRequired, isCapturing]);
  return useMemo(
    () => ({
      hasLoadedABBYY,
      requiredPhotos,
      verificationRequired,
      setVerificationRequired,
      setRequiredPhotos,
      setProcessingPhotos,
      processingPhotos,
      resetUseABBYState,
      isCapturing,
      setIsCapturing,
      mutliPageError,
      setMultipageError,
    }),
    [requiredPhotos, processingPhotos, verificationRequired, mutliPageError]
  );
}
