//Converts blob to file
async function blobtoFile(blob, filename, type = "images/png") {
  const dataUrl = await blobToDataUrl(blob);
  return {
    dataUrl: dataUrl,
    name: filename,
    original: new File([blob], filename, { type }),
    type,
  };
}

//Converts blob to image dataUrl to display
const convertBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

async function blobToDataUrl(file) {
  const dataURL = await convertBase64(file);
  dataURL.replace(",", "---");
  return dataURL;
}

//Invokes aidCapture and returns result as array of fileList
export default async function useAIDCapture(event, settings = null, signal = null) {
  const result =
    event === "CUSTOM_DOCUMENT"
      ? window.ABBYY.WebCapture.aidCapture(event, signal, settings)
      : event === "SELFIE" || event === "FAR_SELFIE"
      ? window.ABBYY.WebCapture.aidCapture("CUSTOM_SELFIE", signal, settings)
      : window.ABBYY.WebCapture.aidCapture(event);
  return result.then((ref) => storeImage(ref));
}

//Store result of aidCapture event in fileList
function storeImage(ref) {
  //check if promised result is empty
  if (ref == null) {
    return;
  }
  const fileList = [];
  for (let i = 0; i <= ref.length - 1; i++) {
    const image = ref[i];
    const filename = `mwc-${new Date().getTime()}.jpg`;
    const filePromise = blobtoFile(image.capturedImage, filename);
    fileList[i] = filePromise;
  }
  return Promise.all(fileList);
}
