import { isAbbyyFlow } from "../helpers/constants";

export const STATUSID = {
  FAILED: 4,
  SUCCESS: 5,
  RETAKE: 8,
};

export const LOADING_TIMER = 30000;

export const failureStatus = ["XLF", "XSF", "EXF", "XBT", "XID", "XAD", "XMS", "XMF", "XMV", "XUE", "IPF"];
export const aidStatuses = ["PEN", "RTS", "RTD", "CNT", "EXS", "SUC", "RSC"];
export const iproovStatuses = ["IPN", "IPR", "IPC", "SUC"];

export const LOADING_MESSAGES = [
  {
    key: "screen-modal-presence-verification-uploadYourDocuments",
    value: "Uploading images",
  },
  {
    key: "screen-modal-presence-verification-verifyingYourDocuments",
    value: "Verifying images",
  },
  {
    key: "screen-modal-presence-verification-processingYourDocuments",
    value: "Processing images",
  },
];

export const STATUS_TEXT = {
  [STATUSID.FAILED]: {
    key: "screen-modal-presence-verification-selfie-failed-message",
    value: "The system couldn't verify your photo(s). Please visit a DMV office to finalize your REAL ID Application.",
  },
  [STATUSID.SUCCESS]: {
    key: "screen-modal-presence-verification-selfie-success-message",
    value: "Identity verified.\nSelect 'Next' to continue and finalize your application.",
  },
  [STATUSID.RETAKE]: {
    key: "screen-modal-presence-verification-selfie-retake-message",
    value: "There was a problem verifying your photo(s).",
  },
};

export const BUTTON_TEXT = {
  [STATUSID.FAILED]: {
    key: "screen-modal-presence-verification-next",
    value: "Next",
  },
  [STATUSID.SUCCESS]: {
    key: "screen-modal-presence-verification-next",
    value: "Next",
  },
  [STATUSID.RETAKE]: {
    key: "screen-modal-presence-verification-reTake",
    value: "Retry",
  },
};

export const OPT_IN_OUT_MESSAGE = {
  message1: {
    key: "screen-modal-presence-verification-eligible-to-complete",
    value: "You may be eligible to complete your REAL ID application online.",
  },
  message2: {
    key: "screen-modal-presence-verification-select-optin-to-continue",
    value: "Select 'Yes' to receive your REAL ID without visiting a DMV office. You must verify your identity by taking two photos of yourself.",
  },
  message3: {
    key: "screen-modal-presence-verification-select-optout-for-appointment",
    value: "OR select 'No' and finalize your application at your local DMV office.",
  },
};

//const failedEligibleForExtendedVerification = (application) => application.eligibleForExtendedVerification
const successMTS = (documentUpload) => (failureStatus.includes(documentUpload?.MTS) || documentUpload?.MTS === "SUC");
export const pendingRetakePV = (documentUpload) => ["PEN", "RSC"].includes(documentUpload?.MTS);
export const iproovPendingRetakePV = (documentUpload) => ["IPN", "IPR"].includes(documentUpload?.MTS);

const checkDocStatus = (document) => {
  const allDocs = [];
  if (["PEN", "INP", "DNQ", "SUC"].includes(document?.ATD)) {
    allDocs.push(document.statusId);
  } else if (failureStatus.includes(document?.ATD) && document?.statusId === 4) {
    allDocs.push(0);
  } else {
    allDocs.push(document.statusId);
  }
  return allDocs;
};

const checkFinalConfirmationFlow = (application, documentUploads) => {
  let allDocs = [];
  let rawDocs = [];
  let docsVerifiedNum;
  if (!application?.hasOwnProperty("documents") && documentUploads?.[6]) {
    const documentsArray = documentUploads[6];
    if (["SUC", "EXF"].includes(documentsArray[0].MTS) && ["SUC", "EXF"].includes(documentsArray[0].MTS)) {
      return true;
    } else {
      return false;
    }
  }
  if (documentUploads?.[8]) {
    [...documentUploads?.[8]].map((doc) => (allDocs = allDocs.concat(checkDocStatus(doc))));
    rawDocs = [...documentUploads?.[8]];
  }
  if (documentUploads?.[7]) {
    [...documentUploads?.[7]].map((doc) => (allDocs = allDocs.concat(checkDocStatus(doc))));
    rawDocs = [...rawDocs, ...documentUploads?.[7]];
  }
  if (rawDocs.length > 0) {
    const checkEXF = rawDocs.filter((doc) => (isAbbyyFlow(doc.ATD) && doc.statusId > 1 && doc.statusId !== 4) ||
      (isFailureStatus(doc.ATD) && doc.allowAbbyy === false));
    if (checkEXF.length) {
      docsVerifiedNum = checkEXF.length;
    } else {
      docsVerifiedNum = allDocs.reduce((counter, docStatus) => (docStatus === 5 ? counter + 1 : counter), 0);
    }
    return docsVerifiedNum === allDocs.length;
  }
  return false;
};

export const generateUrl = (application, documentUploads) => {
  const documentUpload = documentUploads[6]?.[0];
  if (checkFinalConfirmationFlow(application, documentUploads)) {
    return "/final-confirmation";
  }
  if (successMTS(documentUpload)) {
    return "/document-upload";
  }
  if (pendingRetakePV(documentUpload) || iproovPendingRetakePV(documentUpload)) {
    return "presence-verification";
  }
  return null;
};

export const generateErrorUrl = (error, businessProgram) => {
  const url = `/${businessProgram}`;

  if (error.name === "MaintenanceEnabled") {
    return `${url}/under-maintenance`;
  }
  // Expired token
  if (error.response?.status === 401) {
    return `${url}/expired`;
  }
  // Bad token
  if (error.response?.status === 404) {
    return `${url}/error`;
  }
};

export const getCaptureType = (documentUpload) => {
  const mtsStatus = documentUpload?.MTS;
  if (aidStatuses.includes(mtsStatus)) {
    return "AID";
  } else if (iproovStatuses.includes(mtsStatus)) {
    return "IPROOV";
  } else {
    return "ABBYY";
  }
};

export const isQualifiedStatus = (status) => {
  return status !== "DNQ";
}

export const isSuccessStatus = (status) => {
  return ["CNT", "EXS", "IPC", "SUC"].includes(status);
};

export const isRetryStatus = (status) => {
  return ["RTD", "RTS", "IPR", "RSC"].includes(status);
};

export const isPendingStatus = (status) => {
  return ["PEN", "IPN"].includes(status);
};

export const isPendingConfirmationStatus = (status) => {
  return status === "PUC";
};

export const isFailureStatus = (status) => {
  return failureStatus.includes(status);
};

export const isAIDCapture = (status) => {
  if (!status) {
    throw new Error("Status is undefined. Check that the document's ATD status is passed as an argument.");
  }
  
  return isPendingStatus(status) || isRetryStatus(status) || isPendingConfirmationStatus(status);
}