import { CATEGORY_TYPE, useApplication, useDocumentUploads } from '@ca-dmv-radv/data';
import { aidEnabledCatDocIds, isPassPort, multipPageCatDocIds } from '@ca-dmv-radv/screens/src/dashboard/helpers/constants';
import { useMounted } from '@ca-dmv-radv/utilities';
import InitializeBBY from '@ca-dmv-radv/utilities/src/abby-util';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';

import useAIDCapture from './useAIDCapture';
import { isAIDCapture } from '@ca-dmv-radv/screens/src/dashboard/PresenceVerification/constants';

const mwcConfig = {
  startPage: 0,
  enableAutoCapture: true,
  enableAutoCrop: true,
  enableCamera: true,
  minCameraResolution: 500,
  enableSharpnessFilter: true,
  enableQualityAssessmentForOCR: true,
  images: [],
  mwcLocalizedStrings: {},
  useSystemCamera: false,
};

async function blobtoFile(blob, filename, type = "images/png") {
  return {
    name: filename,
    original: new File([blob], filename, { type }),
    type,
  };
}

// global variable so useABBYY behaves like a singleton
let isLoadedOnce = false;

export const CAPTURING_PHOTOS_IDLE = "idle";
export const CAPTURING_PHOTOS_PROCESSING = "processing";
export const CAPTURING_PHOTOS_SUCCESS = "success";

export default function useABBYY() {
  const [capturedImages, setCapturedImages] = useState([]);
  const [isCapturing, setIsCapturing] = useState(CAPTURING_PHOTOS_IDLE);
  const [abbyyLoadingError, setAbbyLoadingError] = useState(null);
  const mounted = useMounted();
  const { documentUploads, isMDLDocument } = useDocumentUploads();
  const { docTypes } = useApplication();

  useEffect(() => {
    if (isLoadedOnce || !isMobile || !mounted) {
      return;
    }

    // prevent script/styles from loading multiple times.
    isLoadedOnce = true;

    InitializeBBY(setAbbyLoadingError);
  }, [isMobile, mounted]);

  const handleWebCapture = useCallback(
    async (chosenDocument) => {
      if (!isLoadedOnce) {
        throw new Error("Mobile Web Capture could not be initialized.");
      }

      if (!isMobile || !mounted || isCapturing !== CAPTURING_PHOTOS_IDLE) {
        return false;
      }

      setIsCapturing(CAPTURING_PHOTOS_PROCESSING);

      mwcConfig.images = capturedImages;
      const fileList = [];
      if (chosenDocument.multiplePages === false) {
        mwcConfig.maxImagesCount = 1;
      } else if (chosenDocument.requiredPages) {
        mwcConfig.maxImagesCount = chosenDocument.requiredPages.length;
      } else {
        mwcConfig.maxImagesCount = 10;
      }

      const documentUpload = isMDLDocument ? documentUploads[CATEGORY_TYPE.MDL]?.[0] : documentUploads[CATEGORY_TYPE.DV]?.[0];

      if (
        // return the WebCapture Promise for image processing
        isAIDCapture(chosenDocument.ATD) && aidEnabledCatDocIds.includes(chosenDocument.catDocId)
      ) {
        let aidImageCount = 1;

        if (chosenDocument.multiplePages === true) {
          aidImageCount = chosenDocument.requiredPages.length;
        }

        let aidImages = new Array(aidImageCount).fill();

        if (isPassPort(chosenDocument.catDocId, docTypes)) {
          if (chosenDocument.page === "front" && chosenDocument.accessCamera) {
            const aidImage = await useAIDCapture("PASSPORT");
            chosenDocument.isFront = true;
            chosenDocument.frontImage = aidImage[0];

            aidImages[0] = chosenDocument.frontImage;
          } else {
            return;
          }

          chosenDocument.accessCamera = false;
        } else if (multipPageCatDocIds.includes(chosenDocument.catDocId)) {
          if (chosenDocument.page === "front" && chosenDocument.accessCamera) {
            const frontDocumentSettings = new DocumentSettings();
            frontDocumentSettings.documentType = "License";
            frontDocumentSettings.captureMode = "Manual";
            frontDocumentSettings.overlayText = "Align Front of ID and Tap Screen";
            const aidImageFront = chosenDocument?.categoryId === 7 ? await useAIDCapture("ID_FRONT") : await useAIDCapture("CUSTOM_DOCUMENT", frontDocumentSettings);
            chosenDocument.isFront = true;
            chosenDocument.frontImage = aidImageFront[0];

            aidImages[0] = chosenDocument.frontImage;
            aidImages[1] = chosenDocument.backImage;
          } else if (chosenDocument.page === "back" && chosenDocument.accessCamera) {
            const backDocumentSettings = new DocumentSettings();
            backDocumentSettings.documentType = "License";
            backDocumentSettings.captureMode = "Manual";
            backDocumentSettings.documentSide = "Back";
            backDocumentSettings.overlayText = "Align Back of ID and Tap Screen";
            backDocumentSettings.isBarcodeDetectedEnabled = false;
            const aidImageBack = await useAIDCapture("CUSTOM_DOCUMENT", backDocumentSettings);
            chosenDocument.isBack = true;
            chosenDocument.backImage = aidImageBack[0];

            aidImages[0] = chosenDocument.frontImage;
            aidImages[1] = chosenDocument.backImage;
          } else {
            return;
          }

          chosenDocument.accessCamera = false;
        } else {
          return;
        }

        fileList[0] = aidImages;

        return Promise.all(fileList[0]);
      }

      const images = await window.ABBYY.WebCapture.capture(mwcConfig);

      if (images.length === 0) {
        // setIsCapturing(CAPTURING_PHOTOS_IDLE);
        return false;
      }

      // stash images in case user doesn't upload required images
      setCapturedImages(images);

      // convert each image (blob) to a File
      for (let i = 0; i < images.length; i += 1) {
        const image = images[i];
        const filename = `mwc-${new Date().getTime()}.jpg`;
        const filePromise = blobtoFile(image.capturedImage, filename);
        fileList[i] = filePromise;
      }

      if (!chosenDocument.requiredPages || (chosenDocument.requiredPages && images.length === chosenDocument.requiredPages.length)) {
        setIsCapturing(CAPTURING_PHOTOS_SUCCESS);
      }

      // return the fileList for further processing
      return Promise.all(fileList);
    },
    [capturedImages, isCapturing]
  );
  return useMemo(
    () => ({
      handleWebCapture,
      setCapturedImages,
      setIsCapturing,
      setAbbyLoadingError,
      abbyyLoadingError,
      hasLoadedABBYY: isLoadedOnce,
      isCapturing,
      setIsCapturing,
    }),
    [handleWebCapture, abbyyLoadingError, isLoadedOnce, isCapturing]
  );
}
